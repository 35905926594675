.share-invite {
  z-index: 100;
  position: fixed;

  &.desktop {
    top: 30px;
    right: 30px;
  }

  &.mobile {
    display: none;
    right: 20px;
    bottom: 20px;
  }

  @media all and (max-width: 600px) {
    display: none;
    &.mobile {
      display: block;
    }
  }
}