.dashboard-home {
  max-width: 100% !important;
  padding-bottom: 80px;

  .video-section {
    width: 550px;
    margin: 100px auto 20px auto;
    background-color: #312A77;
    border-radius: 10px;
    overflow: hidden;

    iframe {
      background-color: #312A77;
      border-radius: '15px';
      width: 300px;
      height: 200px;
    }
    
    .right-box, 
    .bottom-box {
      background-color: #312A77;
      text-align: center;
      color: #fff;
      width: 250px;
      padding: 50px 20px;
      h2 {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .bottom-box {
      display: none;
      width: 100%;
      padding: 20px;
    }


    @media all and (max-width: 600px) {
      width: 400px;
      margin-bottom: 50px;

      iframe {
        width: 400px;
      }

      .right-box {
        display: none;
      }

      .bottom-box {
        display: block;
      }
    }

    @media all and (max-width: 450px) {
      width: 350px;
      margin-bottom: 50px;

      iframe {
        width: 360px;
      }
    }
  }

  .start-button {
    @media all and (max-width: 500px) {
      width: 350px;
      margin: 0 auto;
    }
  }
}