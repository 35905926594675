.hero {
  color: #fff;
  background-color: #dcdcdc;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 50px;

  @media all and (max-width: 450px) {
    padding: 50px 10px 0 10px;
  }
}