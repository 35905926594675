.dashboard {
  .image {
    width: 200px;
  }

  .chakra-heading {
    font-size: 30px;
    line-height: 45px;
    color: #444444;
  }

  .step-start {
    border: 2px solid red;

    .chakra-text {
      font-size: 17px;
      line-height: 25px;
      color: #999999;
    }
  }
}

.chakra-modal__close-btn {
  &:hover {
    background: unset !important;
    opacity: 0.9;
  }
  margin: 4px;
  outline: none;

  svg {
    border-radius: 100%;
    border: 3px solid #c8c8c8;
    color: #c8c8c8;
    font-size: 28px;
    padding: 2px;
  }
}

.chart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;

    &--inner {
      width: 30px;
      margin: 0 12px;
      text-align: center;
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 26px;
      text-align: center;
      color: #222222;
      display: flex;
      align-items: center;
      justify-content: center;

      &.emoji {
        font-size: 24px;
      }
    }

    .purple {
      background: #5f55c3;
      border-radius: 8px;
    }
  }
  .top {
    height: 180px;
    align-items: flex-end;
    margin-bottom: 10px;

    padding-bottom: 10px;
  }
  .divider {
    background: #c8c8c8;
    height: 3px;
    width: 100%;
    margin-bottom: 10px;
  }
}
