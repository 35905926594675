
.earkick-input.chakra-input {
  font-family: Poppins;
  padding: 30px;
  width: 350px;
  margin: 26px 0;
  border: 3px solid  #C8C8C8;
  background: #fff;
  border-radius: 15px;
  font-weight: 700;
  font-size: 20px;

  &:focus {
    border-color: #5F55C3;
  }
}