.features {
  background-color: #F2F1FF;
  color: #444444;
  line-height: 21px;
  font-size: 14px;
  width: 100%;
  max-width: 100% !important;
  padding: 70px 0 10px 0;

  .feature {
    width: 500px;
    margin: 0 auto;
    margin-bottom: 70px;

    @media all and (max-width: 600px) {
      width: 400px;
      margin-bottom: 50px;
    }


    @media all and (max-width: 450px) {
      width: 350px;
    }

    .heading {
      font-weight: 700;
    }
  }
}