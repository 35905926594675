.big-logos {
  max-width: 800px !important;
  width: 800px !important;
  padding-right: 60px !important;
  margin: 0 auto;

  @media all and (max-width: 820px) {
    max-width: 350px !important;
    width: 350px !important;
    padding-right: 20px !important;
    padding-left: 0 !important;
  }

  .image-pg {
    margin-left: 45px;
  }

  @media all and (max-width: 820px) {
    .image-pg {
      margin-left: 0;
    }
  }
}