.footer {
  min-height: 104px;
  width: 100vw;

  .links {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
  }

  &__link {
    display: flex;

    @media all and (max-width: 500px) {
      align-items: center;
      display: flex;
      flex-direction: column;

      .links {
        margin-bottom: 10px;
      }
    }
  }

  &__social {
    @media all and (max-width: 500px) {
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto;

      a {
        margin: 0 15px;
      }
    }

    .disabled {
      opacity: 0.6;
      pointer-events: none;
    }
  }
  &__copy {
    color: #ffffff;
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;

    @media all and (max-width: 1024px) {
      font-size: 10px;
    }
  }
}
