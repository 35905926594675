.delete-modal {
  .icon {
    margin-top: 35px;
  }

  .chakra-heading {
    font-size: 20px;
    font-weight: 500;
    line-height: 128%;
    margin: 6px 0;
  }

  .chakra-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 150%;
  }

  button {
    background-color: transparent;
    font-weight: 500;
    font-size: 17px;
    line-height: 128%;
    color: #a0a0a3;
  }
}