.video-container {
  position: relative;
  border: 3px solid gray;
  width: 335px;
  height: 335px;
  box-sizing: border-box;
  border-radius: 15px;
  overflow: hidden;
  margin: 0 auto;
  margin-bottom: 14px;

  .video-frame {
    width: 470px;
    position: relative;
    height: 470px;

    .chopper {
      background-color: #fff;
      height: 370px;
      position: absolute;
      width: 70px;
      top: -10px;
    }

    .chopper-left {
      left: -72px;
    }

    .chopper-right {
      left: 354px;
    }

    video {
      display: block;
      margin: 0 auto;
      position: absolute;
      top: -59px;
      left: -62px;
      width: 470px;
      height: 470px;
      z-index: -1;
    }
  }

  .handle {
    cursor: move;
    position: absolute;
    width: 100%;
    height: 30px;
    top: 0;
    left: 0;
    right: 0;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sunglasses-cover {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
}
