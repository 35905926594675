.wave-container {
  display: flex;
  align-items: center;
  position: relative;
  

  svg {
    margin-left: 20px;
    margin-right: 20px;
    width: 18px;
    height: 18px;
    cursor: pointer;
  }

  .stop-playing {
    width: 18px;
    height: 18px;
    border-radius: 6px;
    background: black;
    margin-left: 20px;
    margin-right: 20px;
    cursor: pointer;
  }

  .waveform-wrapper {
    background: #f3f2e9;
    border-radius: 10px;
    width: 308px;
    height: 49px;
    display: flex;
    align-items: center;

    @media all and (max-width: 560px) {
      width: 100%;
    }

    p {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 300;
      font-size: 13px;
      line-height: 26px;
      color: #000000;
      margin-top: 0px;
      margin-left: 0px;
    }

    #waveform {
      height: 100%;
      padding-top: 10px !important;
      width: 200px;

      @media all and (max-width: 600px) {
        width: 370px;
      }

      > wave {
        height: 30px !important;
        overflow: hidden !important;

        > wave {
          border-right: unset !important;
        }
      }
    }
  }

  canvas {
    width: 180px !important;
    aspect-ratio: auto 764 / 180;

    @media all and (max-width: 600px) {
      width: 180px !important;
    }
  }
}

.wave-container-column {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  flex-direction: column;
}

.audio-container {
  margin: 0 auto;
  padding-left: 20px;

  @media all and (max-width: 1349px) {
    margin-left: 0px;
    align-items: center;
  }

  button {
    width: 370px;
    margin-top: 50px;
    height: 63px;
    background: #5F55C3;
    border-radius: 15px;
    border: none;
    font-weight: 700;
    font-size: 17px;
    line-height: 60px;
    text-align: center;
    color: #ffffff;
    border-bottom: 10px solid #312A77;
    outline: none;
    user-select: none;

    @media all and (max-width: 600px) {
      width: 100%;
      font-size: 30px;
      margin-top: 50px;
      height: unset;
      padding: 5px 0;
    }

    @media all and (max-width: 480px) {
      width: 50%;
      margin: 0;
      margin-top: 30px;
    }

    &:active {
      background: #5583f8;
      border-bottom: 10px solid #2d5fdf;
    }
  }
}

.wave-actions-style {
  display: flex;
  margin-top: 20px;
}


.delete-bin-style {
  position: absolute;
  right: 10px;
  width: 50px;
  height: 30px;
  top: -40px;

  svg {
    width: 30px;
    color: #000;
  }
}
